
import icon_example from '../src/assets/images/icons/1.png';

export default {
    "pallette": {
        primary: "#D1D2F9",  // light green
        primary_light: "#576490",  // dark green
        secondary: "#A3BCF9",  // dark blue
        secondary_light: "#8EE4AF",  // light green
        contrast: "#EDF5E1"  // yellow?
    },
    "SEO": {
        "title": "Qomix",
        "description": ""
    },
    "Header": {
        "menuItems": [
            {
                "path": "home",
                "label": "Home",
            },
            {
                "path": "about",
                "label": "About",
            },
            {
                "path": "services",
                "label": "Services",
            },
            // {
            //     "path": "advisory",
            //     "label": "Advisory Board"
            // },
            {
                "path": "contact",
                "label": "Contact",
            },
        ]
    },
    "Banner": {
        "Motto": "Making sense of data"  // "Developing personalized therapies for autoimmune diseases"
    },
    "About": {
        "title": "About",
        "text": "Qomix is all about doing computational biology on complex datasets with a focus on robustness and result interpretability. We offer services for all experimental stages. We can accomodate any NGS technique, including multiomics single-cell sequencing."
    },
    "Contact": {
        "title": "Contact",
        "text": "For any inquiries, please contact us at qomix.eu@gmail.com."
    },
    "Footer": {}
}
